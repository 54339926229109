<template>
    <v-container class="role_assigner " grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">{{type==='update'?'Update Role':'Add Role'}}</v-card-title>
                    <v-card-text>
                        <v-layout row wrap>
                            <v-flex xs6>
                                <v-form ref="form" @submit.prevent="save"
                                        @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                        lazy-validation>
                                    <v-text-field
                                            hint="New role may take few moments to take effects. Please co-ordinate with our support after registering a new role."
                                            :disabled="!isCompanyRole && type!=='add'" v-model="form.name"
                                            :error-messages="form.errors.get('name')"
                                            outlined dense
                                            autocomplete="off" label="Role Name *" required/>
                                </v-form>
                            </v-flex>
                            <v-flex xs6>
                                <v-select :items="services" label="Service"
                                          outlined dense
                                          v-model="service"/>
                            </v-flex>
                        </v-layout>
                    </v-card-text>

                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :server-items-length="pagination.rowsPerPage"
                            :items="models"
                            :search="search"
                            :options.sync="pagination"
                            :loading="false">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index+1}}</td>
                                <td class="text-left">{{ item.name }}</td>
                                <td class="text-left">
                                    <v-chip :color="selectedRoles.indexOf(permission.id)>-1?'primary':''"
                                            v-on:click="updatePermission(permission.id)"
                                            outlined small
                                            v-for="(permission,key) in item.permissions" :key="key" :close="false"
                                            class="mr-1">
                                        <v-avatar v-if="selectedRoles.indexOf(permission.id)>-1">
                                            <v-icon small>{{ 'check_circle' }}</v-icon>
                                        </v-avatar>
                                        {{permission.name}}
                                    </v-chip>
                                    <p v-if="item.permissions.length<1" class="text-dagner">{{item.name}} has no
                                        permission defined yet.</p>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>


                    <v-card-actions class="pr-3 pt-5">
                        <v-spacer/>
                        <!--<v-btn color="warning" outlined @click="">Cancel</v-btn>-->
                        <v-btn v-if="type=='add'" color="success" outlined @click="save">Save</v-btn>
                        <v-btn v-if="type=='update'" color="success" outlined @click="save"
                               :disabled="selectedRoles.length === rolesRecordLength">Update
                        </v-btn>
                    </v-card-actions>


                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                name: '',
                permissions: []
            }, '/api/roles'),
            models: [],
            search: null,
            isCompanyRole: false,
            pagination: {
                rowsPerPage: 50
            },
            services: [],
            service: 'all',
            permissions: {},
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Name', align: 'left', value: 'name', width: 200},
                {text: 'Permission', align: 'left', sortable: false}
            ],
            selectedRoles: [],
            rolesRecordLength: 0,
            type: 'add',
            roleId: '',
        }),
        mounted() {
            this.getModels();
            this.fetchRoleInfo();
        },
        watch: {
            'service': function () {
                this.getModels();
            },
            'pagination'() {

            }
        },
        methods: {
            getModels() {
                this.$rest.get('/api/modules/with-permission?service=' + this.service).then(({data}) => {
                    this.models = data.data;
                    this.services = [{value: 'all', text: 'ALL'}];
                    data.services.map(res => {
                        this.services.push({value: res.id, text: res.name});
                    });
                });
            },
            fetchRoleInfo() {
                if (this.$route.params.roleSlug) {
                    this.type = 'update';
                    this.$rest.get('/api/roles/' + this.$route.params.roleSlug + '/permissions/all').then(({data}) => {
                        console.log(data);
                        if (Object.keys(data).length > 0) {
                            this.selectedRoles = data.permissions;
                            this.rolesRecordLength = data.permissions.length;
                            this.form.name = data.name;
                            this.roleId = data.id;
                            this.isCompanyRole = data.my;
                        }
                    });
                }
            },
            updatePermission(id) {

                if (this.selectedRoles.indexOf(id) === -1)
                    this.selectedRoles.push(id);
                else
                    this.selectedRoles.splice(this.selectedRoles.indexOf(id), 1)
            },
            save() {
                this.form.permissions = this.selectedRoles.join(',');
                if (this.type === 'add') {
                    this.form.store().then(res => {
                        this.selectedRoles = [];
                        this.$events.fire('notification', {message: res.data.message})
                    });
                } else if (this.type === 'update') {
                    this.$rest.post('/api/roles/' + this.roleId, {
                        '_method': 'put',
                        name: this.form.name,
                        permissions: this.form.permissions
                    }).then(({data}) => {
                        this.$events.fire('notification', {message: data.message});
                        this.$router.push({name: 'roles'});
                    }).catch(err => {
                        this.$events.fire('notification', {message: err.response.data.message, status: 'error'})
                        this.form.failure(err.response.data);
                    });
                }
            }
        }
    }
</script>
<style lang="scss">
    .role_assigner {
        .theme--light.v-chip {
            border-radius: 2px;

            &.primary {
                background-color: #0064c7;
                color: white;
            }
        }
    }
</style>